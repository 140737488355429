<template>
  <vf-carousel
    v-if="isCarousel"
    class-container="relative"
    class-controls="top-5 mt-2 mx-2 "
    size-controls="md"
    observe-resize
    loop
    @init="({ activeItem }) => pushUpsellImpression(activeItem)"
    @to-prev="({ activeItem }) => pushUpsellImpression(activeItem)"
    @to-next="({ activeItem }) => pushUpsellImpression(activeItem)"
  >
    <product-upsell
      v-for="(upsell, i) in upsellsWithInventory"
      :key="i"
      :model-value="modelValue"
      v-bind="{ upsell, showTitle, showModal, showCheckbox, showButton, showDetails }"
      :class-title="{ 'ml-8': showCheckbox && upsellsWithInventory.length > 1 }"
      data-test-id="product-upsell"
      @update:model-value="$emit('update:modelValue', $event)"
      @rec-click="$emit('recClick', $event)"
      @open-quickshop="$emit('openQuickshop', $event)"
    />
  </vf-carousel>
  <div v-else>
    <div v-for="(upsell, i) in upsellsWithInventory" :key="i" data-test-id="product-upsell">
      <product-upsell
        :model-value="modelValue"
        v-bind="{ upsell, showTitle, showModal, showCheckbox, showButton, showDetails }"
        :class="{ 'mt-3': i }"
        @update:model-value="$emit('update:modelValue', $event)"
        @rec-click="$emit('recClick', $event)"
        @open-quickshop="$emit('openQuickshop', $event)"
      />
      <hr
        v-if="i !== (upsellsWithInventory.length - 1)"
        class="m-a b-grey-50 bg-grey-50"
        style="max-width: 98%"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Product } from '#root/api/clients/product/data-contracts'

const props = defineProps<{
  modelValue: string[]
  showModal?: boolean
  showTitle?: boolean
  showButton?: boolean
  showCheckbox?: boolean
  showDetails?: boolean
  upsells: any[]
  isCarousel?: boolean
  skipCheckInventory?: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [payload: string[]]
  'recClick': [payload: string]
  'openQuickshop': [payload: Product]
}>()

const { $gtm } = useNuxtApp()

const inventory = ref()
const isInventoryLoaded = ref(false)

const fetchInventory = async () => {
  isInventoryLoaded.value = false
  if (props.skipCheckInventory || !props.upsells?.length) {
    inventory.value = []
  }
  else {
    inventory.value = await Promise.all(
      props.upsells.map(({ id }) => useApi().products.$inventory(id))
    )
  }
  isInventoryLoaded.value = true
}

const upsellsWithInventory = computed(() => {
  if (!isInventoryLoaded.value || props.skipCheckInventory || !inventory.value.length)
    return props.upsells

  return props.upsells.map((upsell, i) => {
    const { quantity = 0 } = inventory.value[i]?.variants[upsell.sku] || {}
    return {
      ...upsell,
      productInventoryState: quantity > 0 ? 'InStock' : 'OutOfStock'
    }
  }).filter(({ productInventoryState }) => productInventoryState === 'InStock')
})

const pushUpsellImpression = (index: number) => $gtm.push('upsell.onUpsellImpression', upsellsWithInventory.value[index].id)

watch(() => props.upsells, () => fetchInventory())

onUnmounted(() => {
  emit('update:modelValue', [])

  !props.isCarousel && upsellsWithInventory.value.forEach((_, i) => pushUpsellImpression(i))
})
</script>
